import React from "react";

import ResearchSignUpForm from "../components/ResearchSignUpForm";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/_research.scss";

const ResearchArticle = ({ pageContext }) => {
  const {
    title,
    body,
    pdfVersion,
    shortSummary,
    summaryImage,
  } = pageContext;
  return (
    <Layout>
      <SEO
        title={title}
        keywords={["Flowspring", "research", "fund flows", "fees"]}
        description={shortSummary.shortSummary}
        ogImage={summaryImage.file.url}
      />
      <div id="researchArticle" className="researchContent">
        {body ? (
          <div dangerouslySetInnerHTML={{ __html: body.body }} />
        ) : (
          <object data={pdfVersion.file.url} id="pdfContentWindow">
            <p>
              {"Your browser can't display PDF documents. You can download and view the article "}
              <a href={pdfVersion.file.url}>HERE</a>
            </p>
          </object>
        )}
        <div id="articleSideBar">
          <ul>
            <li>
              <a
                target="_blank"
                href="https://twitter.com/share?ref_src=twsrc%5Etfw"
                data-show-count="false"
                rel="noreferrer"
              >
                <i className="fab fa-twitter-square fa-fw fa-lg" />
                <div className="socialLabel">{" Share on Twitter"}</div>
              </a>
              <script
                async
                src="https://platform.twitter.com/widgets.js"
                charSet="utf-8"
              />
            </li>
            <li>
              <div
                data-href={pdfVersion.file.url}
                data-layout="button"
                data-size="small"
                data-mobile-iframe="true"
              >
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.flowspring.com%2Fresearch%2F&amp;src=sdkpreparse"
                  className="fb-xfbml-parse-ignore"
                  rel="noreferrer"
                >
                  <i className="fab fa-facebook-square fa-fw fa-lg" />
                  <div className="socialLabel">{" Share on Facebook"}</div>
                </a>
              </div>
            </li>
            <li>
              <div>
                <a target="_blank" href={pdfVersion.file.url} rel="noreferrer">
                  <i className="fas fa-file-pdf fa-fw fa-lg" />
                  <div className="socialLabel">{"   Open as PDF"}</div>
                </a>
              </div>
            </li>
          </ul>
        </div>
        <ResearchSignUpForm />
      </div>
    </Layout>
  );
};

export default ResearchArticle;
